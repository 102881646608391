import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const FormAddOrder = () => {
  const [tanggalPemanenan, setTanggalPemanenan] = useState("");
  const [varietasSingkong, setVarietasSingkong] = useState("");
  const [estimasiBerat, setEstimasiBerat] = useState("");
  const [estimasiHarga, setEstimasiHarga] = useState("");
  const [hargaPerKg, setHargaPerKg] = useState({});
  const [lahanOptions, setLahanOptions] = useState([]);
  const [selectedLahan, setSelectedLahan] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  const saveProduct = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("token");

    try {
      await axios.post(
        `https://c-greenproject.org:8000/products`, 
        {
          idLahan: selectedLahan,
          tanggalPemanenan,
          varietasSingkong,
          estimasiBerat,
          estimasiHarga,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate("/orderaktif");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");

      try {
        // Fetch harga perusahaan
        const responseHarga = await axios.get(
          `https://c-greenproject.org:8000/perusahaan`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const perusahaan = responseHarga.data;
        if (perusahaan && perusahaan.length > 0) {
          const { hargaGradeA, hargaGradeB, hargaGradeC } = perusahaan[0];
          setHargaPerKg({
            "Grade A": hargaGradeA,
            "Grade B": hargaGradeB,
            "Grade C": hargaGradeC,
          });
        }

        // Fetch daftar lahan
        const responseLahan = await axios.get(
          `https://c-greenproject.org:8000/lahan`, 
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setLahanOptions(responseLahan.data);
        console.log("Data lahan yang diterima:", responseLahan.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setMsg("Gagal mengambil data lahan atau harga.");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (varietasSingkong) {
      const harga = hargaPerKg[varietasSingkong] || 0;
      setEstimasiHarga(harga * estimasiBerat);
    }
  }, [varietasSingkong, hargaPerKg, estimasiBerat]);

  return (
    <div>
      <h2 className="title pt-0">
        <strong>Add Order Pemanenan</strong>
      </h2>

      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content ps-4 pt-2">
            <form onSubmit={saveProduct}>
              <p className="has-text-centered">{msg}</p>
              <div className="field mb-3">
                <label className="label">Pilih Lahan</label>
                <div className="control">
                  <div className="select">
                    <select
                      className="input"
                      value={selectedLahan}
                      onChange={(e) => setSelectedLahan(e.target.value)}
                    >
                      <option value="">Pilih Lahan</option>
                      {lahanOptions.map((lahan) => (
                        <option key={lahan.lahan} value={lahan.lahan}>
                          {`${lahan}`}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="field mb-3">
                <label className="label">Tanggal Panen</label>
                <div className="control">
                  <input
                    type="date"
                    className="input"
                    value={tanggalPemanenan}
                    onChange={(e) => setTanggalPemanenan(e.target.value)}
                  />
                </div>
              </div>
              <div className="field mb-3">
                <label className="label">Varietas / Jenis Singkong</label>
                <div className="control">
                  <div className="select">
                    <select
                      className="input"
                      value={varietasSingkong}
                      onChange={(e) => setVarietasSingkong(e.target.value)}
                    >
                      <option value="">Pilih Varietas</option>
                      <option value="Grade A">Grade A</option>
                      <option value="Grade B">Grade B</option>
                      <option value="Grade C">Grade C</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="field mb-3">
                <label className="label">Estimasi Berat (kg)</label>
                <div className="control">
                  <input
                    type="number"
                    className="input"
                    value={estimasiBerat}
                    onChange={(e) => setEstimasiBerat(e.target.value)}
                  />
                </div>
              </div>
              <div className="field mb-3">
                <label className="label">Estimasi Harga Total (Rp)</label>
                <div className="control">
                  <input
                    type="number"
                    className="input"
                    value={estimasiHarga}
                    readOnly
                  />
                </div>
              </div>

              <div className="field mb-3">
                <div className="control">
                  <button type="submit" className="btn btn-success">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormAddOrder;
