import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import "./component.css";

const ListHistoryProduk = () => {
  const { user } = useSelector((state) => state.auth);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (user) {
      getProductsByRole();
    }
  }, [user]);

  const getProductsByRole = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("Token not found");
      return;
    }

    try {
      const response = await axios.get(
        `https://c-greenproject.org:8000/historyorder`,
        {
          params: {
            // Mengirimkan peran pengguna dalam parameter untuk menyesuaikan permintaan
            role: user.role,
            name: user.name, // Nama pengguna sesuai dengan perannya
            email: user.email, // Email pengguna sesuai dengan perannya
            userId: user.uuid, // User ID petani, hanya diperlukan jika peran 'petani'
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setProducts(response.data); // Menyimpan data produk yang diterima
    } catch (error) {
      console.error("Error fetching products by role:", error);
    }
  };

  return (
    <div>
      <h2 className="title">
        <strong>History Order Panen</strong>
      </h2>

      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>No</th>
            <th>Nama Petani</th>
            <th>Varietas</th>
            <th>Nama Logistik</th>
            <th>Nama Pabrik</th>
            <th>Tanggal Panen</th>
            <th>Berat(kg)</th>
            <th>Harga Aktual</th>
            <th>Kode Blockchain</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product, index) => (
            <tr key={product.uuid}>
              <td>{index + 1}</td>
              <td>{product.user?.name || "No Name"}</td>
              <td>{product.varietasSingkong}</td>
              <td>{product.namaLogistik || "No Logistics"}</td>
              <td>{product.namaPabrik || "No Factory"}</td>
              <td>{product.tanggalPemanenan}</td>
              <td>{product.estimasiBerat} kg</td>
              <td>Rp. {product.estimasiHarga}</td>
              <td>{product.uuid}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListHistoryProduk;
