import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import "./component.css";

const DetailUser = () => {
  const { user } = useSelector((state) => state.auth);
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // State untuk loading
  const [errorMessage, setErrorMessage] = useState(""); // State untuk error

  useEffect(() => {
    if (user?.uuid) {
      const fetchData = async () => {
        const token = localStorage.getItem("token");

        if (!token) {
          setErrorMessage("Token tidak ditemukan. Silakan login kembali.");
          setIsLoading(false);
          return;
        }

        try {
          const response = await axios.get(
            `https://c-greenproject.org:8000/users/${user.uuid}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setUserData(response.data);
        } catch (error) {
          console.error("Error fetching data:", error);
          if (error.response?.status === 404) {
            setErrorMessage("User tidak ditemukan.");
          } else if (error.response?.status === 401) {
            setErrorMessage("Unauthorized. Silakan login kembali.");
          } else {
            setErrorMessage("Gagal memuat data user.");
          }
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    }
  }, [user?.uuid]);

  if (isLoading) {
    return <p>Loading user details...</p>;
  }

  if (errorMessage) {
    return <p className="error-message">{errorMessage}</p>;
  }

  return (
    <div>
      <h2 className="title mt-3">
        <strong>User Details</strong>
      </h2>

      <div className="card ps-4 pt-4 pb-4 mb-4">
        {userData ? (
          <div className="user-details-container d-flex">
            <div className="col-4 d-flex justify-content-center align-items-center">
              {userData.additionalInfo?.url ? (
                <img
                  src={userData.additionalInfo.url}
                  alt={userData.name}
                  className="user-image"
                  style={{
                    width: "200px",
                    height: "200px",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <p>Foto tidak tersedia</p>
              )}
            </div>
            <div className="col-8">
              <table className="user-data-table">
                <tbody>
                  <tr>
                    <th>ID Blockchain</th>
                    <td>:</td>
                    <td>{userData.uuid || "-"}</td>
                  </tr>
                  <tr>
                    <th>Nama</th>
                    <td>:</td>
                    <td>{userData.name || "-"}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>:</td>
                    <td>{userData.email || "-"}</td>
                  </tr>
                  <tr>
                    <th>Nomor HP</th>
                    <td>:</td>
                    <td>{userData.additionalInfo?.nohp || "-"}</td>
                  </tr>
                  <tr>
                    <th>Alamat</th>
                    <td>:</td>
                    <td>{userData.additionalInfo?.alamat || "-"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <p>Data user tidak tersedia.</p>
        )}
      </div>
    </div>
  );
};

export default DetailUser;
