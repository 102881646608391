import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import "./component.css";
import {
  IoBagCheck,
  IoCheckmarkCircleSharp,
  IoPencilSharp,
  IoSync,
} from "react-icons/io5";

const ListOrderAktif = () => {
  const { user } = useSelector((state) => state.auth);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `https://c-greenproject.org:8000/orderberlangsung`,
        {
          headers: { Authorization: `Bearer ${token}` },
          withCredentials: true,
        }
      );
      setProducts(response.data);
    } catch (error) {
      setMsg(error.response ? error.response.data.msg : "Error fetching data");
    }
  };

  const updateStatus = async (productId, action) => {
    const token = localStorage.getItem("token");
    setLoading(true);
    try {
      const response = await axios.patch(
        `https://c-greenproject.org:8000/products/${productId}/status`,
        { action },
        {
          headers: { Authorization: `Bearer ${token}` },
          withCredentials: true,
        }
      );
      setMsg(response.data.msg);
      getProducts();
    } catch (error) {
      setMsg(error.response ? error.response.data.msg : "Error updating data");
    } finally {
      setLoading(false);
    }
  };

  const formatRupiah = (harga) => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    }).format(harga);
  };

  return (
    <div>
      <h2 className="title">
        <strong>Order Berlangsung</strong>
      </h2>
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr className="text-center">
            <th>No</th>
            <th>Nama Petani</th>
            <th>Varietas</th>
            <th>Status Order</th>
            <th>Tanggal Panen</th>
            <th>Estimasi Berat (kg)</th>
            <th>Harga</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className="text-center">
          {products.map((product, index) => (
            <tr key={product.uuid}>
              <td>{index + 1}</td>
              <td>{product.user?.name || "No Name"}</td>
              <td>{product.varietasSingkong}</td>
              <td>{product.statusOrder}</td>
              <td>{product.tanggalPemanenan}</td>
              <td>{product.estimasiBerat}</td>
              <td>{formatRupiah(product.estimasiHarga)}</td>
              <td >
                <button
                  className="btn btn-primary button-tabel"
                  onClick={() => updateStatus(product.uuid, "update")}
                >
                  <IoSync /> Update
                </button>

                {/* Button untuk Pabrik */}
                {user.role === "pabrik" &&
                  product.statusOrder ===
                    "Menunggu Pabrik dan Logistik Menyetujui" && (
                    <button
                      className="btn btn-success mt-2 button-tabel"
                      onClick={() =>
                        updateStatus(product.uuid, "approve-pabrik")
                      }
                    >
                      <IoBagCheck /> Terima
                    </button>
                  )}

                {/* Button untuk Logistik */}
                {user.role === "logistik" &&
                  product.statusOrder ===
                    "Perusahaan dan Pabrik Menyetujui, menunggu logistik" && (
                    <button
                      className="btn btn-success mt-2 button-tabel"
                      onClick={() =>
                        updateStatus(product.uuid, "approve-logistik")
                      }
                    >
                      <IoBagCheck /> Terima
                    </button>
                  )}

                {/* Button untuk Perusahaan */}
                {user.role === "perusahaan" &&
                  product.statusOrder === "diterima pabrik" && (
                    <button
                      className="btn btn-success mt-2 button-tabel"
                      onClick={() =>
                        updateStatus(product.uuid, "complete-order")
                      }
                    >
                      <IoCheckmarkCircleSharp /> Selesai
                    </button>
                  )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {msg && <p className="notification is-danger">{msg}</p>}
      {loading && <p className="notification is-info">Loading...</p>}
    </div>
  );
};

export default ListOrderAktif;
