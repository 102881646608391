import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { IoBagCheck, IoCloseCircleSharp } from "react-icons/io5";

const ListOrderMasuk = () => {
  const { user } = useSelector((state) => state.auth);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    const token = localStorage.getItem("token");
    setLoading(true);

    try {
      const response = await axios.get(
        `https://c-greenproject.org:8000/ordermasuk`,
        {
          withCredentials: true,
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setProducts(response.data);
    } catch (error) {
      setMsg(error.response ? error.response.data.msg : "Gagal memuat data.");
    } finally {
      setLoading(false);
    }
  };

  const updateStatus = async (productId, action) => {
    const token = localStorage.getItem("token");
    setLoading(true);

    try {
      const payload = {};
      if (user.role === "perusahaan") payload.perusahaan = action === "approve";
      if (user.role === "pabrik") payload.pabrik = action === "approve";
      if (user.role === "logistik") payload.logistik = action === "approve";

      const response = await axios.patch(
        `https://c-greenproject.org:8000/products/${productId}`,
        payload,
        {
          withCredentials: true,
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setMsg(response.data.msg);
      getProducts(); // Memuat ulang data setelah status diubah
    } catch (error) {
      setMsg(error.response ? error.response.data.msg : "Gagal mengubah status.");
    } finally {
      setLoading(false);
    }
  };

  const formatRupiah = (harga) =>
    new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    }).format(harga);

  const getStatusLabel = (product) => {
    switch (product.statusOrder) {
      case "pending":
        return "Menunggu Persetujuan Perusahaan";
      case "Perusahaan menyetujui, menunggu pabrik dan logistik":
        return "Menunggu Persetujuan Pabrik dan Logistik";
      case "Perusahaan dan Pabrik Menyetujui, menunggu logistik":
        return "Menunggu Persetujuan Logistik";
      case "Order Berlangsung":
        return "Order Sedang Berlangsung";
      default:
        return "Status Tidak Diketahui";
    }
  };

  return (
    <div>
      <h2 className="title">
        <strong>Order Masuk</strong>
      </h2>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <table className="table is-striped is-fullwidth">
          <thead>
            <tr className="text-center">
              <th>No</th>
              <th>Nama Petani</th>
              <th>Varietas</th>
              <th>Status Order</th>
              <th>Tanggal Panen</th>
              <th>Estimasi Berat (kg)</th>
              <th>Harga</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {products.length > 0 ? (
              products.map((product, index) => {
                return (
                  <tr key={product.uuid}>
                    <td>{index + 1}</td>
                    <td>{product.user?.name || "No Name"}</td>
                    <td>{product.varietasSingkong}</td>
                    <td className="text-center">{getStatusLabel(product)}</td>
                    <td className="text-center">{product.tanggalPemanenan}</td>
                    <td className="text-center">{product.estimasiBerat}</td>
                    <td className="text-center">{formatRupiah(product.estimasiHarga)}</td>
                    <td className="text-center">
                      {user.role === "perusahaan" && product.statusOrder === "pending" && (
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                          <button
                            className="btn btn-success mb-2 button-tabel"
                            onClick={() => updateStatus(product.uuid, "approve")}
                          >
                            <IoBagCheck /> Terima
                          </button>
                          <button
                            className="btn btn-danger button-tabel"
                            onClick={() => updateStatus(product.uuid, "reject")}
                          >
                            <IoCloseCircleSharp /> Tolak
                          </button>
                        </div>
                      )}

                      {user.role === "pabrik" && product.statusOrder === "Perusahaan menyetujui, menunggu pabrik dan logistik" && (
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                          <button
                            className="btn btn-success mb-2 button-tabel"
                            onClick={() => updateStatus(product.uuid, "approve")}
                          >
                            <IoBagCheck /> Terima
                          </button>
                          <button
                            className="btn btn-danger button-tabel"
                            onClick={() => updateStatus(product.uuid, "reject")}
                          >
                            <IoCloseCircleSharp /> Tolak
                          </button>
                        </div>
                      )}

                      {user.role === "logistik" && product.statusOrder === "Perusahaan dan Pabrik Menyetujui, menunggu logistik" && (
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                          <button
                            className="btn btn-success mb-2 button-tabel"
                            onClick={() => updateStatus(product.uuid, "approve")}
                          >
                            <IoBagCheck /> Terima
                          </button>
                          <button
                            className="btn btn-danger button-tabel"
                            onClick={() => updateStatus(product.uuid, "reject")}
                          >
                            <IoCloseCircleSharp /> Tolak
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="8" className="text-center">
                  Tidak ada data order.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      {msg && (
        <div className={`notification ${msg.includes("berhasil") ? "is-success" : "is-danger"}`}>
          {msg}
        </div>
      )}
    </div>
  );
};

export default ListOrderMasuk;
