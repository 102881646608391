import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import axios from "axios";
import "./component.css";
import { IoPerson } from "react-icons/io5";

const Welcome = () => {
  const { user } = useSelector((state) => state.auth);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    // Pastikan user dan user.uuid tersedia sebelum melakukan fetch
    if (user && user.uuid) {
      const fetchData = async () => {
        try {
          const token = localStorage.getItem("token");
          if (!token) {
            console.error("Token not found");
            return;
          }

          const response = await axios.get(
            `https://c-greenproject.org:8000/users/${user.uuid}`,
            {
              headers: {
                Authorization: `Bearer ${token}`, // Menambahkan Bearer token di header
              },
              withCredentials: true, // Pastikan untuk mengirim kredensial
            }
          );

          console.log(response.data);
          setUserData(response.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, [user, user?.uuid]);

  return (
    <Card className="card-welcome">
      <Card.Body className="text-center d-flex flex-column justify-content-center align-items-center">
        {userData?.foto ? (
          <img
            src={userData.foto} // Menggunakan URL foto dari response
            alt={userData.name}
            className="user-image mb-3 mt-3"
            style={{
              width: "150px",
              height: "150px",
              objectFit: "cover",
            }}
          />
        ) : (
          // Tampilkan ikon IoPerson hanya jika tidak ada foto profil
          <div
            className="user-image d-flex justify-content-center align-items-center mb-3 mt-3"
            style={{
              width: "150px",
              height: "150px",
            }}
          >
            <IoPerson style={{ fontSize: "100px" }} />
          </div>
        )}

        <Card.Title>
          <h4>
            <strong>Welcome Back!</strong>
          </h4>
        </Card.Title>
        <Card.Subtitle className="mb-2">
          <h3>
            <b>{user && user.name}</b>
          </h3>
        </Card.Subtitle>
        <Card.Text className="mb-3">
          <small>Role {user && user.role}</small>
          <br />
          <small>{user && user.uuid}</small>
          <br />
          <small>{user && user.email}</small>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default Welcome;
