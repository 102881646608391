import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const EditProfile = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [nohp, setNohp] = useState("");
  const [alamat, setAlamat] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [foto, setFoto] = useState(null);
  const [role, setRole] = useState("");
  const [msg, setMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const getUserById = async () => {
      const token = localStorage.getItem("token");

      try {
        const response = await axios.get(
          `https://c-greenproject.org:8000/users/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const { name, email, role, additionalInfo } = response.data;
        setName(name);
        setEmail(email);
        setRole(role); // Role tidak dapat diubah di frontend
        setNohp(additionalInfo.nohp || "");
        setAlamat(additionalInfo.alamat || "");
      } catch (error) {
        setMsg(error.response?.data?.msg || "Error mendapatkan data pengguna.");
      }
    };

    getUserById();
  }, [id]);

  const updateUser = async (e) => {
    e.preventDefault();
    setMsg("");
    if (password && password !== confPassword) {
      setMsg("Password dan Konfirmasi Password tidak cocok.");
      return;
    }

    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("nohp", nohp);
      formData.append("alamat", alamat);
      if (password) formData.append("password", password);
      if (confPassword) formData.append("confPassword", confPassword);
      if (foto) formData.append("foto", foto);

      const token = localStorage.getItem("token");

      await axios.patch(
        `https://c-greenproject.org:8000/users/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setMsg("Profil berhasil diperbarui!");
      setTimeout(() => {
        navigate(`/profile-${role.toLowerCase()}`);
      }, 1500); // Tunggu sebelum redirect
    } catch (error) {
      setMsg(error.response?.data?.msg || "Error saat memperbarui data.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFoto(selectedFile);
    }
  };

  return (
    <div>
      <h2 className="title mt-3">
        <strong>Update Profile : {role}</strong>
      </h2>
      <div className="card is-shadowless">
        <div className="card-content ps-4 mb-3">
          <div className="content">
            <form onSubmit={updateUser}>
              <p className="has-text-centered">{msg}</p>
              {isLoading && <p className="has-text-centered">Loading...</p>}

              <div className="field mb-2">
                <label className="label">Nama</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Name"
                    required
                  />
                </div>
              </div>

              <div className="field mb-2">
                <label className="label">Email</label>
                <div className="control">
                  <input
                    type="email"
                    className="input"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                  />
                </div>
              </div>

              <div className="field mb-2">
                <label className="label">Nomor HP</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={nohp}
                    onChange={(e) => setNohp(e.target.value)}
                    placeholder="Nomor HP"
                  />
                </div>
              </div>

              <div className="field mb-2">
                <label className="label">Alamat</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={alamat}
                    onChange={(e) => setAlamat(e.target.value)}
                    placeholder="Alamat"
                  />
                </div>
              </div>

              <div className="field mb-2">
                <label className="label">Password</label>
                <div className="control">
                  <input
                    type="password"
                    className="input"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password (opsional)"
                  />
                </div>
              </div>

              <div className="field mb-2">
                <label className="label">Konfirmasi Password</label>
                <div className="control">
                  <input
                    type="password"
                    className="input"
                    value={confPassword}
                    onChange={(e) => setConfPassword(e.target.value)}
                    placeholder="Konfirmasi Password"
                  />
                </div>
              </div>

              <div className="field mb-2">
                <label className="label">Foto</label>
                <div className="control">
                  <input
                    type="file"
                    className="input"
                    onChange={handleFileChange}
                    placeholder="Foto"
                    accept="image/*"
                  />
                </div>
              </div>

              <div className="field mt-3">
                <div className="control">
                  <button type="submit" className="btn btn-success" disabled={isLoading}>
                    {isLoading ? "Updating..." : "Update"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
